<template>
  <div class="experience">
    <div class="main">
      <section class="left">
        <img src="../../assets/images/Experience.svg" alt="" />
      </section>
      <section class="right">
        <article>
          <h2>Développeur Full-Stack</h2>
          <h3><span class="year">2019 - auj. </span> Ministère des Armées</h3>
          <p>
            Au sein du Centre de Développement des Applications de la Défense de
            la DIRISI, nous répondons au besoin de la transformation digitale
            des différentes armées.
          </p>
        </article>
        <article>
          <h2>Développeur WEB</h2>
          <h3><span class="year">2019</span> Fromation GRETA Var</h3>
          <p>
            Développeur et chef de projet d'une équipe de 5 stagiaires Nous
            devions développer une application web de réservation de traversées
            maritimes.
          </p>
        </article>
        <article>
          <h2>Administrateur réseaux</h2>
          <h3><span class="year">2017</span> Mairie d'Hyeres</h3>
          <p>
            Stage d'un mois en administration systèmes et réseaux informatiques.
            J'ai été amené à configurer plusieurs éléments réseau tels que des
            switchs, routeurs, firewalls.
          </p>
        </article>
        <article>
          <h2>Développeur</h2>
          <h3><span class="year">2017</span> Publijet</h3>
          <p>
            Stage d'un mois en développement web, création d’un système
            d’administration adaptable à divers sites web. Technologies : php,
            MySql, JavaScript.
          </p>
        </article>
        <article>
          <h2>Employé commercial</h2>
          <h3><span class="year">2016 - 2019</span> Groupe Casino</h3>
          <p>
            Employé commercial confirmé d'une équipe de 6 personnes lors des
            activités saisonnières. Occasionnellement responsable du rayon et
            formateur.
          </p>
        </article>
        <article>
          <h2>Dév - co-fondateur</h2>
          <h3><span class="year">2013 - 2021</span> Groupe Absolute Gaming</h3>
          <p>
            Fondateur d'un groupe de conception de sites et d’outils de jeux
            vidéo, communauté de plus milliers membres et possédant des
            partenariats avec Ubisoft et Deep Silver.
          </p>
        </article>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Experience"
};
</script>

<style scoped>
h2 {
  font-size: 20px;
  margin-bottom: 10px;
  background-color: var(--background-accent);
  color: var(--text-dark);
  padding: 5px;
  display: inline-flex;
}

h3 {
  font-size: 18px;
  margin-bottom: 4px;
}

.main {
  display: flex;
  justify-content: space-around;
}
section {
  width: 750px;
}

section.left {
  display: flex;
}

section.right {
  padding: 100px 0;
  display: flex;
  flex-wrap: wrap;
  margin-right: 100px;
}

section.right article {
  width: 350px;
  margin-bottom: 50px;
  margin-right: 50px;
}
section.right article:nth-child(2n + 2) {
  margin-right: 0;
}
section.right article:nth-last-child(-n + 2) {
  margin-bottom: 0;
}

section.right article p {
  /*text-align: justify;*/
}

.year {
  color: var(--text-accent);
}

@media only screen and (max-width: 1624px) {
  section.right {
    width: 80%;
    justify-content: center;
    margin: 0;
  }
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 15px;
  }
  p {
    font-size: 14px;
  }

  section.right article {
    width: 40%;
    min-width: 250px;
    margin-bottom: 50px;
    margin-right: 50px;
  }
}

@media only screen and (max-width: 1064px) {
  section.left {
    display: none;
  }
  section.right {
    width: 80%;
    justify-content: center;
    margin: 0;
  }
  section.right article {
    width: 100%;
  }
  section.right article:nth-last-child(-n + 2) {
    margin-bottom: 50px;
  }
}
</style>
