<template>
  <div class="main">
    <div class="soft">
      <div class="content">
        <h2>Hard Skills</h2>
        <div class="skills">
          <div class="skill">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div class="logo">
              <img src="../../assets/images/vuejs.svg" alt="Logo de VueJS" />
              <h3>Vue.js</h3>
            </div>
          </div>
          <div class="skill">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
            <div class="logo">
              <img src="../../assets/images/nodejs.svg" alt="Logo de NodeJs" />
              <h3>Node.js</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/mongodb.svg"
                alt="Logo de MongoDB"
              />
              <h3>MongoDB</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img src="../../assets/images/angular.svg" alt="Logo d'Angular" />
              <h3>Angular</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/symfony.svg"
                alt="Logo de Symfony"
              />
              <h3>Symfony</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/postgresql.svg"
                alt="Logo de postgresql"
              />
              <h3>PostgreSQL</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img src="../../assets/images/github.svg" alt="Logo de Github" />
              <h3>Git / Github</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/webstorm.svg"
                alt="Logo de WebStorm"
              />
              <h3>WebStorm</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/infinity.svg"
                alt="Logo de Webstorm"
              />
              <h3>DevOps</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="hard">
      <div class="content">
        <h2>Soft Skills</h2>
        <div class="skills">
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/skills/passionate.svg"
                alt="Passionné"
              />
              <h3>Passionné</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/skills/self.svg"
                alt="Autodidacte"
              />
              <h3>Autodidacte</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/skills/sociable.svg"
                alt="Sociable"
              />
              <h3>Sociable</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/skills/autonomous.svg"
                alt="Autodidacte"
              />
              <h3>Autonome</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img src="../../assets/images/skills/trust.svg" alt="Fiable" />
              <h3>Fiable</h3>
            </div>
          </div>
          <div class="skill">
            <div class="logo">
              <img
                src="../../assets/images/skills/mind.svg"
                alt="Ouvert d'esprit"
              />
              <h3>Ouvert</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Skill"
};
</script>

<style scoped>
body {
}

.main {
  display: flex;
  width: 100%;
  justify-content: space-around;
  padding: 100px 0;
}

.main .soft,
.main .hard {
  width: 700px;
}

.main .soft h2,
.main .hard h2 {
  margin-bottom: 15px;
}

.main .skills {
  display: flex;
  flex-wrap: wrap;
}

.main .skill:hover {
  transition-duration: 0.2s;
  transition-delay: 0.3s;
}

.main .skill {
  width: 200px;
  height: 200px;
  margin: 15px 50px 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(0%, 0%);
  overflow: hidden;
  background: var(--background-main);
  position: relative;
}

.main .skill:nth-child(3n + 3) {
  margin-right: 0;
}

.main .skill img {
  width: 100px;
  height: 100px;
}

.main .skill .logo {
  flex-wrap: wrap;
  text-align: center;
}

.main .skill .logo h3 {
  font-size: 25px;
  font-weight: 700;
}

.main .skill:before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  transition: 0.5s;
  pointer-events: none;
  transform: skewX(-30deg);
}

.main .skill span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}

.main .skill span:nth-child(1) {
  transform: rotate(0deg);
}

.main .skill span:nth-child(2) {
  transform: rotate(90deg);
}

.main .skill span:nth-child(3) {
  transform: rotate(180deg);
}

.main .skill span:nth-child(4) {
  transform: rotate(270deg);
}

.main .skill span:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background: var(--background-accent);
  animation: animate 4s linear infinite;
}
@media only screen and (max-width: 1624px) {
  .main .skill {
    width: 150px;
    height: 150px;
    margin: 15px;
    background: var(--background-main);
  }
  .main .skill img {
    width: 75px;
    height: 75px;
  }
}

@media only screen and (max-width: 1075px) {
  .main {
    flex-wrap: wrap;
  }
  .main .skills {
    justify-content: center;
  }
  .main .soft,
  .main .hard {
    width: 100%;
  }

  .main .hard {
    margin-top: 50px;
  }

  .main .skill {
    margin: 10px;
  }
  .main .skill:nth-child(3n + 3) {
    margin-right: 10px;
  }
  .main .skill .logo h3 {
    font-size: 20px;
  }
}

@keyframes animate {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }
  50% {
    transform: scaleX(1);
    transform-origin: left;
  }
  50.1% {
    transform: scaleX(1);
    transform-origin: right;
  }

  100% {
    transform: scaleX(0);
    transform-origin: right;
  }
}
</style>
