<template>
  <div class="experience">
    <div class="main">
      <section class="left">
        <div class="educations">
          <article>
            <h2>Diplôme d'ingénieur</h2>
            <h3>
              <span class="year">2021 - 2024</span>
              Ingénieur informatique et multimédia
            </h3>
            <p>
              Diplôme d’ingénieur spécialité Informatique et Multimédia en
              apprentissage. <br />
              (anct. STMN : Sciences et Technologies des Médias Numériques).
              <br />
              Habilité par la CTI.
            </p>
          </article>
          <article>
            <h2>Brevet de Technicien Supérieur</h2>
            <h3><span class="year">2019 - 2021</span> BTS SIO option SLAM</h3>
            <p>
              Brevet de Technicien Supérieur Services Informatiques aux
              Organisations option Solutions Logicielles et Applications
              Métiers. <br />
            </p>
          </article>
          <article>
            <h2>Formation DEV WEB</h2>
            <h3><span class="year">2019</span> Formation GRETA Var</h3>
            <p>
              Formation Développement WEB : JavaScript, Node.js, Angular, php,
              MySQL, Symfony.
            </p>
          </article>
          <article>
            <h2>Titre de Technicien Informatique</h2>
            <h3><span class="year">2016 - 2017 </span> Titre RNCP niv IV</h3>
            <p>
              Obtention du titre professionnel Technicien d’Assistance
              Informatique de niveau IV (bac).
            </p>
          </article>
          <article>
            <h2>Autodidacte</h2>
            <h3>Projets / Veille informatique</h3>
            <p>
              Divers projets et veille informatique quotidienne. <br />
              Outils : Daily.dev, Feedly, Raindrop, divers sites et réseaux
              sociaux.
            </p>
          </article>
        </div>
      </section>
      <section class="right">
        <img src="../../assets/images/education.svg" alt="" />
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "Education"
};
</script>

<style scoped>
h2 {
  font-size: 20px;
  margin-bottom: 10px;
  background-color: var(--background-accent);
  color: var(--text-dark);
  padding: 5px;
  display: inline-flex;
}

h3 {
  font-size: 18px;
  margin-bottom: 4px;
}

article {
  margin-bottom: 40px;
}

.main {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

section {
  width: 750px;
}

section.right {
  display: flex;
  justify-content: center;
}

section.left {
  padding: 100px 0;
  margin-left: 125px;
}

.experience {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  justify-content: space-between;
}

.year {
  color: var(--text-accent);
}
@media only screen and (max-width: 1624px) {
  section.left {
    width: 80%;
  }
  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 15px;
  }
  p {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1064px) {
  section.right {
    display: none;
  }
  section.left {
    width: 80%;
    margin: 0;
  }
}
</style>
