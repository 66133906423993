<template>
  <a @click="scrollTop" v-show="visible" class="bottom-right">
    <a class="scroll-up">
      <span class="left-bar"></span>
      <span class="right-bar"></span>
      <svg width="40" height="40">
        <line class="top" x1="0" y1="0" x2="120" y2="0" />
        <line class="left" x1="0" y1="40" x2="0" y2="-80" />
        <line class="bottom" x1="40" y1="40" x2="-80" y2="40" />
        <line class="right" x1="40" y1="0" x2="40" y2="1200" />
      </svg>
    </a>
  </a>
</template>

<script>
export default {
  name: "topArrow",
  data() {
    return {
      visible: false
    };
  },
  methods: {
    scrollTop() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    },
    scrollListener() {
      this.visible = window.scrollY > 150;
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollListener);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollListener);
  }
};
</script>

<style scoped>
.bottom-right {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
}
/*Fleche*/

.scroll-up {
  height: 40px;
  width: 40px;
  display: block;
  outline: 1px solid rgba(255, 255, 255, 0.08);
  position: relative;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
}
.scroll-up svg line {
  stroke-width: 1;
  stroke: #b7b7b7;
  fill: none;
  stroke-dasharray: 20;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
.scroll-up:hover svg line.top {
  -webkit-transform: translateX(-40px);
  transform: translateX(-40px);
}
.scroll-up:hover svg line.bottom {
  -webkit-transform: translateX(40px);
  transform: translateX(40px);
}
.scroll-up:hover svg line.left {
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
}
.scroll-up:hover svg line.right {
  -webkit-transform: translateY(-40px);
  transform: translateY(-40px);
}

.scroll-up .left-bar {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  top: 19px;
  left: 5px;
  width: 18px;
  display: block;
  transform: rotate(-45deg);
}
.scroll-up .left-bar:after {
  content: "";
  background-color: #666;
  width: 18px;
  height: 1px;
  display: block;
  border-radius: 1px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
  transform-origin: right center;
  z-index: -1;
}
.scroll-up .right-bar {
  position: absolute;
  z-index: 1;
  background-color: transparent;
  top: 19px;
  left: 17px;
  width: 18px;
  display: block;
  transform: rotate(45deg);
  border-radius: 2px;
}
.scroll-up .right-bar:after {
  content: "";
  background-color: #666;
  width: 18px;
  height: 1px;
  display: block;
  border-radius: 1px;
  transition: all 0.5s cubic-bezier(0.25, 1.7, 0.35, 1.5);
  transform-origin: left center;
  z-index: -1;
}
.scroll-up:hover {
  transition: all 0.1s;
}
.scroll-up:hover .left-bar:after {
  transform: rotate(-10deg);
}
.scroll-up:hover .right-bar:after {
  transform: rotate(10deg);
}
</style>
