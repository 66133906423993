<template>
  <div class="about">
    <header-nav></header-nav>
    <main id="container">
      <section class="personalInfos">
        <article class="description">
          <div class="left">
            <img src="../assets/images/personal_data.svg" alt="" />
          </div>
          <div class="right">
            <h2 class="name">
              Thybault Gobert
              <span class="subName"> 25 ans d'Hyères (Var) </span>
            </h2>
            <h3>Description</h3>
            <p class="textDescription">
              Passionné des nouvelles technologies, je suis développeur Full
              Stack spécialisé en JavaScript et son écosystème (Vuejs, Nodejs,
              MongoDB).<br />
              De formation autodidacte, j’ai repris les études en alternance
              afin d’acquérir de nouvelles compétences.
            </p>
          </div>
        </article>
      </section>
      <section class="bg-ligth">
        <education></education>
      </section>
      <section>
        <experience></experience>
      </section>
      <section class="bg-ligth">
        <skill></skill>
      </section>
      <topArrow>
        ==>
      </topArrow>
    </main>
  </div>
</template>

<script>
import Skill from "@/components/about/Skill";
import Experience from "@/components/about/Experience";
import Education from "@/components/about/Education";
import topArrow from "@/components/TopArrow";
import HeaderNav from "../components/HeaderNav";

export default {
  name: "about",
  metaInfo: {
    title: "A propos"
  },
  components: {
    HeaderNav,
    education: Education,
    experience: Experience,
    skill: Skill,
    topArrow: topArrow
  }
};
</script>

<style scoped>
#container {
  display: flex;
  flex-direction: column;
}

h2 {
  font-size: 40px;
  margin-bottom: 40px;
}

section {
  padding: 0 10px;
}

section.personalInfos {
  height: 750px;
}

.personalInfos .name {
  display: block;
}

.personalInfos .subName {
  display: block;
  line-height: 40px;
  font-size: 34px;
}

.personalInfos p {
  width: 90%;
}

h3 {
  font-size: 30px;
  margin-bottom: 10px;
}

section.bg-ligth {
  background-color: var(--background-secondary);
}

.description {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.description img {
  width: 100%;
}

.description .left {
  width: 750px;
}

.description .right {
  width: 750px;
  padding-top: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.infosLists {
  width: 100%;
  display: flex;
}

.infosLists ul {
  width: 50%;
  margin: 0;
}

.infosLists li {
  margin-bottom: 10px;
}

.infosLists li span {
  min-font-size: 100px;
}

.back {
  display: flex;
  align-items: center;
  fill: #fdfdfd;
  padding: 10px;
  margin-left: 15px;
}

.resume .selectButton {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.resume .selectButton .router-link-active {
  background: none;
}

.resume .selectButton a {
  background: #3c3c3c;
  text-align: center;
  flex-grow: 1;
  font-size: 23px;
  text-transform: uppercase;
  font-weight: 700;
  padding: 10px 0;
}

.resume .selectButton a:hover {
  background-color: hsla(225, 73%, 57%, 0.75);
  transition: all 0.2s ease-in-out;
}

.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.2s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

@media only screen and (max-width: 750px) {
  .description {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .description .left {
    display: none;
  }
  .description .right {
    width: 90%;
  }

  .personalInfos p {
  }
}
</style>
